// Core
import { useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from "react-i18next";
import { useRouter } from "next/navigation";

// Components
import { Text } from "@/client/components/ui/Typography/Text";
import { Button } from "@/client/components/ui/Button";
import { Form } from "@/client/components/ui/Form";
import { InputForm } from "@/client/components/ui/FormFields/InputForm";
import { InputCheckbox } from "@/client/components/ui/FormFields/InputCheckbox";
import { WrappedFormInput } from "@/client/components/form/wrapped-form-input";
import { WrappedFormSelect } from "@/client/components/form/wrapped-form-select";
import { Space } from "@/client/components/ui/Space";
import { TitleBlock } from "src/client/components/form/title-block";
import { Tooltip } from "@/client/components/ui/Tooltip";
import { Link } from "@/client/components/block-elements/link";

// Definitions
import type { SelectItem } from "@/client/components/ui/FormFields/InputSelect/InputSelect.types";
import type { FormSubmitFn } from "@/client/components/form/form.types";
import { UserCountryCodes } from "@/client/domains/user/types";

// Hooks
import { useToggle } from "@/client/hooks/use-toggle";

// Feature
import { inputConfig, SignupRequestFields, signupRequestForm } from "./config";
import type { SignupRequestType } from "../../types";
import st from "./styles.module.css";

// Utils
import { getFieldsMetaValidation } from "@/client/utils/forms";
import { routes } from "@/client/definitions/book";
import { getCountryItemByLocale, mapCountriesToIcons } from "@/client/utils/maps";

export type SignupRequestFormPropsType = {
  phoneCodes?: SelectItem[];
  country?: keyof typeof UserCountryCodes;
  formName?: string;
  loadingCreate: boolean;
  loadingStaticData: boolean;
  onSubmit: FormSubmitFn<SignupRequestType>;
  testId?: string;
};
export const SignupRequestForm = ({
  phoneCodes = [],
  country,
  formName = "pro-trader-signup-request-form",
  loadingCreate,
  loadingStaticData,
  testId,
  onSubmit,
}: SignupRequestFormPropsType) => {
  const { t } = useTranslation(["buttons", "inputs", "signup"]);
  const router = useRouter();
  const { isToggle, onToggle } = useToggle(true);
  const phonePrefixWithIcons = useMemo(() => mapCountriesToIcons(phoneCodes), [phoneCodes]);
  const defaultPhonePrefixOption = country
    ? getCountryItemByLocale(phonePrefixWithIcons, country)
    : null;

  const signupRequestFormProps = useForm<SignupRequestType>({
    defaultValues: { ...signupRequestForm.shape },
    resolver: zodResolver(signupRequestForm.schema(t)),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, setValue, setError, reset } = signupRequestFormProps;
  const { isSubmitting } = formState;
  const fieldsMeta = getFieldsMetaValidation(SignupRequestFields, formState);

  const handleLogin = () => {
    router.push(routes.signin);
  };

  const handleExpandUserConsent = () => onToggle(!isToggle);

  useEffect(() => {
    defaultPhonePrefixOption?.icon &&
      setValue(SignupRequestFields.phonePrefix, defaultPhonePrefixOption);
  }, [phoneCodes, country, setValue]);

  const onSubmitForm = () => {
    void (async () => {
      await handleSubmit((values) => {
        onSubmit?.({
          values,
          acts: {
            setError,
            reset,
          },
        });
      })();
    })();
  };

  return (
    <Form onFinish={onSubmitForm} name={formName} data-testid={testId}>
      <TitleBlock>{t("signup:form.title")}</TitleBlock>

      <InputForm
        id={SignupRequestFields.name}
        name={SignupRequestFields.name}
        validateStatus={fieldsMeta.name.status}
        {...fieldsMeta.name.helpText}
      >
        <WrappedFormInput
          name={SignupRequestFields.name}
          control={control}
          placeholder={t(inputConfig[SignupRequestFields.name].placeholder)}
          hasDisabled={isSubmitting}
        />
      </InputForm>

      <InputForm
        htmlFor={SignupRequestFields.phoneNumber}
        type="no-gutter"
        skeletonType="group"
        loading={loadingStaticData}
      >
        <Space type="phone">
          <InputForm
            id={SignupRequestFields.phonePrefix}
            name={SignupRequestFields.phonePrefix}
            validateStatus={fieldsMeta.phonePrefix.status}
            {...fieldsMeta.phonePrefix.helpText}
          >
            <WrappedFormSelect
              id={SignupRequestFields.phonePrefix}
              name={SignupRequestFields.phonePrefix}
              control={control}
              options={phonePrefixWithIcons}
              placeholder={t(inputConfig.phonePrefix.placeholder)}
              hasDisabled={isSubmitting}
              validateStatus={fieldsMeta.phonePrefix.status}
            />
          </InputForm>
          <InputForm
            id={SignupRequestFields.phoneNumber}
            name={SignupRequestFields.phoneNumber}
            validateStatus={fieldsMeta.phoneNumber.status}
            {...fieldsMeta.phoneNumber.helpText}
          >
            <WrappedFormInput
              id={SignupRequestFields.phoneNumber}
              name={SignupRequestFields.phoneNumber}
              control={control}
              placeholder={t(inputConfig[SignupRequestFields.phoneNumber].placeholder)}
              hasDisabled={isSubmitting}
              hasPhoneMask
              unmaskedValue
            />
          </InputForm>
        </Space>
      </InputForm>

      <InputForm
        id={SignupRequestFields.email}
        name={SignupRequestFields.email}
        validateStatus={fieldsMeta.email.status}
        {...fieldsMeta.email.helpText}
      >
        <WrappedFormInput
          name={SignupRequestFields.email}
          control={control}
          hasDisabled={isSubmitting}
          placeholder={t(inputConfig[SignupRequestFields.email].placeholder)}
        />
      </InputForm>

      <InputForm testId="signup-request-form-consent-checkbox">
        <InputCheckbox align="top" value={isToggle} onChange={handleExpandUserConsent}>
          <Text color="gray-900" size="12" testId="signup-request-form-consent-expand">
            {t("signup:form.consentShort")}
            <Tooltip
              placement="bottomRight"
              type="form-hint"
              color="white"
              arrow={false}
              title={
                <Text size="12" color="gray-700">
                  <Trans
                    i18nKey="signup:form.consent"
                    components={[
                      <Link key={routes.privacyPolicy} href={routes.privacyPolicy} size="12" />,
                    ]}
                  />
                </Text>
              }
            >
              <a role="link-tooltip" onClick={(e) => e.preventDefault()}>
                {t("common:collapse.more")}
              </a>
            </Tooltip>
          </Text>
        </InputCheckbox>
      </InputForm>

      <div className={st.actions}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          block
          loading={isSubmitting}
          disabled={loadingStaticData || loadingCreate || isSubmitting || !isToggle}
          data-testid="signup-request-btn-access"
        >
          <Text size="16" fontWeight="600">
            {t("buttons:registration")}
          </Text>
        </Button>
        <Button
          href={routes.signin}
          type="link"
          size="large"
          block
          mode="custom"
          onClick={handleLogin}
          data-testid="signup-request-btn-login"
        >
          <Text size="16" fontWeight="600">
            {t("buttons:signIn")}
          </Text>
        </Button>
      </div>
    </Form>
  );
};
